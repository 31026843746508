function init_matomo(biscuits) {
  const defaultOptions = {
    trackerUrl: 'https://analytics.ableton.com/matomo.php',
    tagManagerScript: 'https://analytics.ableton.com/js/container_R5JVetMm.js',
    siteId: '1',
    applyCustomSettings: true
  };
  const options = Object.assign({}, defaultOptions, window.matomoOptions || {});

  window._paq = window._paq || [];
  window._mtm = window._mtm || [];

  function mato() {
    window._paq.push(Array.from(arguments));
  }

  function prependScript(src) {
    let elem = document.createElement('script');
    elem.type = 'text/javascript';
    elem.src = src;
    let firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(elem, firstScript);
  }

  function load_matomo() {
    mato('requireConsent');
    mato('requireCookieConsent');
    mato('setTrackerUrl', options.trackerUrl);
    mato('setSiteId', options.siteId);

    // track custom Ableton file formats as downloads
    mato('addDownloadExtensions', [
      'alp',
      'abl',
      'ablbundle',
      'adg',
      'agr',
      'adv',
      'alc',
      'als',
      'ams',
      'amxd',
      'asd',
      'ask'
    ]);
    if (options.applyCustomSettings) {
      mato('enableLinkTracking');
      mato('enableHeartBeatTimer', 5); // enable heart-beat, wait 5 secs before sending
      mato('setDomains', ['*.ableton.com']);
      mato('enableCrossDomainLinking');
      mato('trackPageView');
    }

    if (options.tagManagerScript) {
      window._mtm.push({
        'mtm.startTime': new Date().getTime(),
        event: 'mtm.Start'
      });
      prependScript(options.tagManagerScript);
    }
    if (options.script) {
      prependScript(options.script);
    }
  }

  function enable_matomo() {
    mato('setConsentGiven');
    mato('setCookieConsentGiven');
  }

  function disable_matomo() {
    mato('forgetConsentGiven');
    mato('forgetCookieConsentGiven');
  }

  if (options.trackerUrl) {
    load_matomo();
    biscuits.when('matomo', enable_matomo, disable_matomo);
  }
  return mato;
}

window['mato'] = init_matomo(biscuits);
